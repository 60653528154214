class Core {
	publicToken;
	debugEnabled = false;

	constructor(publicToken, options = {}) {
		this.publicToken = publicToken;
		this.debugEnabled = options.debugEnabled || false;
	}

	get providers() {
		return Object.freeze({
			TICIMAX: "ticimax",
			TSOFT: "tsoft",
			AKINON: "akinon",
			IKAS: "ikas",
		});
	}

	get host() {
		return window.location.host;
	}

	get path() {
		return window.location.pathname;
	}

	get isDebugEnabled() {
		return this.debugEnabled;
	}

	get eventTypes() {
		return Object.freeze({
			ADD_TO_CART: "add_to_cart",
			REMOVE_FROM_CART: "remove_from_cart",
			CHECKOUT: "checkout",
			EMPTY_CART: "empty_cart",
			CART_SNAPSHOT: "cart_snapshot",
			FAVORITE_PRODUCTS_SNAPSHOT: "favorite_products_snapshot",
		});
	}

	get eventPaths() {
		return Object.freeze({
			ADD_TO_CART: "addToCart",
			REMOVE_FROM_CART: "removeFromCart",
			CHECKOUT: "checkout",
			EMPTY_CART: "emptyCart",
			CART_SNAPSHOT: "cartSnapshot",
			FAVORITE_PRODUCTS_SNAPSHOT: "favoriteProductSnapshot",
		});
	}

	getEventPath(eventType) {
		const key = Object.entries(this.eventTypes).find(
			([_, value]) => value === eventType,
		)[0];

		return this.eventPaths[key];
	}

	get baseApiUrl() {
		return "https://ixk37pdv92.execute-api.eu-central-1.amazonaws.com/prod";
	}

	get getRequestOptions() {
		return {
			method: "POST",
			headers: this.getRequestHeaders,
			redirect: "follow",
			// mode: "no-cors",
		};
	}

	saveMemberToLocalStorage(member) {
		localStorage.setItem("connexease-crm-member", JSON.stringify(member));
	}

	removeMemberFromLocalStorage() {
		localStorage.removeItem("connexease-crm-member");
	}

	getMemberFromLocalStorage() {
		const member = localStorage.getItem("connexease-crm-member");

		return member ? JSON.parse(member) : null;
	}

	get getRequestHeaders() {
		const headers = new Headers();

		headers.append("Content-Type", "www-form-urlencoded");
		headers.append("X-App-Token", this.publicToken);

		return headers;
	}

	stringToHash(input) {
		if (typeof input !== "string") {
			return null;
		}

		let hash = 0;

		if (input.length === 0) return hash;

		for (let i = 0; i < input.length; i++) {
			const char = input.charCodeAt(i);
			hash = (hash << 5) - hash + char;
			hash |= 0;
		}

		return Math.abs(hash)?.toString();
	}

	saveEvent(type, rawRequestData) {
		const path = this.getEventPath(type);
		const requestData = {
			...rawRequestData,
			requestPath: this.path,
			eventType:
				type === this.eventTypes.CHECKOUT
					? "checkout"
					: type === this.eventTypes.ADD_TO_CART
						? "add_to_cart"
						: type === this.eventTypes.REMOVE_FROM_CART
							? "remove_from_cart"
							: type === this.eventTypes.EMPTY_CART
								? "empty_cart"
								: type === this.eventTypes.CART_SNAPSHOT
									? "cart_snapshot"
									: "favorite_products_snapshot",
		};
		const options = {
			...this.getRequestOptions,
			body: JSON.stringify(requestData),
		};

		try {
			console.log("[CN-CRM] [DEBUG] Sending event: ", path, requestData);

			fetch(`${this.baseApiUrl}/events/${path}`, options);
		} catch (error) {
			console.error("[CN-CRM] Error while sending event: ", error);
		}
	}
}

window.RegisteredConnexeaseModules = {
	...window.RegisteredConnexeaseModules,
	Core,
};
